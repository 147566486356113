import styled from 'styled-components';

const Column = styled('div')`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-grow: 1;
`;

export default Column;
