import styled from 'styled-components';
import { compose, position, layout } from 'styled-system';
import Suggestion from '../suggestion/suggestion.component';

const SuggestionList = styled(
    ({
        suggestions = [],
        component: Component = Suggestion,
        onSuggestionClick = () => null,
        selectedSuggestionIndex,
        children,
        ...styles
    }) => {
        return (
            <div {...styles}>
                {children}
                {suggestions.map((suggestion, index) => (
                    <Component
                        key={suggestion?.id || suggestion?._id || suggestion.value || index}
                        selected={index === selectedSuggestionIndex}
                        onClick={(e) => onSuggestionClick(e, suggestion)}
                    >
                        {suggestion.value}
                    </Component>
                ))}
            </div>
        );
    }
)`
    ${compose(position, layout)}
    width: 100%;
    display: flex;
    flex-direction: column;
    padding-top: 3px;
    border-radius: 5px;
    z-index: 10;
    background-color: var(--bg-overlay-secondary);
`;

export default SuggestionList;
