import { useContext, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { POST_USER_LOGIN } from '../api/user.api';
import { AuthContext } from '../contexts/auth.context';
import Form from '../components/form/form.elements';
import AnimatedErrorMessage from './components/animated-error-message/animated-error-message.component';
import isValidEmail from '../validation/is-valid-email.validation';
import Grid from '../components/grid/grid.component';

const Login = () => {
    const { saveUser } = useContext(AuthContext);
    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const [errorMessage, setErrorMessage] = useState('');

    const handleOnSubmit = (data) => {
        POST_USER_LOGIN(data).then((data) => {
            if (data.status === 'ERR') {
                setErrorMessage(t('FORM_LOGIN_WRONG_CREDENTIALS'));
                setTimeout(() => setErrorMessage(''), 3000);
                return;
            }
            saveUser({
                token: data.data.token,
                sessionId: data.data.sessionId,
                userId: data.data.userId,
                username: data.data.username,
                email: data.data.email,
            });
        });
    };

    return (
        <Form onSubmit={handleSubmit(handleOnSubmit)}>
            <Grid
                gridTemplateAreas={[
                    `'email' 'password' 'errorMessage'`,
                    `'email password' 'errorMessage errorMessage'`,
                ]}
                gridColumnGap="1em"
                marginBottom="10px"
            >
                <Form.Field area="email">
                    <Form.Label>{t('FORM_EMAIL')}</Form.Label>
                    <Form.InputField hasError={!!errors?.email}>
                        <Form.Input
                            type="email"
                            {...register('email', {
                                required: t('FORM_VALIDATION_REQUIRED'),
                                validate: {
                                    isValidEmail: (value) =>
                                        isValidEmail(value) ||
                                        t('FORM_VALIDATION_EMAIL_NOT_VALID'),
                                },
                            })}
                            placeholder={t('FORM_EMAIL_PLACEHOLDER')}
                        />
                    </Form.InputField>
                    <AnimatePresence>
                        {!!errors?.email && (
                            <AnimatedErrorMessage>
                                {errors?.email.message}
                            </AnimatedErrorMessage>
                        )}
                    </AnimatePresence>
                </Form.Field>
                <Form.Field area="password">
                    <Form.Label>{t('FORM_PASSWORD')}</Form.Label>
                    <Form.InputField hasError={!!errors?.password}>
                        <Form.Input
                            type="password"
                            {...register('password', {
                                required: t('FORM_VALIDATION_REQUIRED'),
                                minLength: {
                                    value: 6,
                                    message: t(
                                        'FORM_VALIDATION_PASSWORD_TOO_SHORT',
                                        {
                                            numOfChars: 6,
                                        }
                                    ),
                                },
                            })}
                            placeholder={t('FORM_PASSWORD_PLACEHOLDER')}
                        />
                    </Form.InputField>
                    <AnimatePresence>
                        {!!errors?.password && (
                            <AnimatedErrorMessage>
                                {errors?.password.message}
                            </AnimatedErrorMessage>
                        )}
                    </AnimatePresence>
                </Form.Field>
                <AnimatePresence>
                    {!!errorMessage && (
                        <Form.Field area="errorMessage">
                            <AnimatedErrorMessage>
                                {errorMessage}
                            </AnimatedErrorMessage>
                        </Form.Field>
                    )}
                </AnimatePresence>
            </Grid>
            <Form.Submit float="right">{t('FORM_SUBMIT_LOGIN')}</Form.Submit>
        </Form>
    );
};

export default Login;
