import styled from 'styled-components';

export const Button = styled('button')`
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    border: none;
    border-radius: 5px;
    background-color: var(--bg-overlay, rgba(125, 125, 125, 0.5));
    color: var(--text-primary, #fff);
    margin: 0 3px;
    cursor: pointer;
    & * {
        pointer-events: none;
    }
`;
export const ButtonText = styled('span')``;
export const ButtonIcon = styled('img')`
    display: block;
    height: 100%;
    padding: 0.5em;
    object-fit: contain;
`;
