import PropTypes from 'prop-types';
import {
    Wrapper,
    RatingBox,
    Rating,
    Heading,
    Description,
    Info,
    InfoIcon,
    Price,
    Currency,
} from './offer.elements';
import Column from '../column/column.component';
import Row from '../row/row.component';

const initialData = {
    rating: undefined,
    heading: 'Offer Heading',
    description: 'Offer Long Description',
    restaurant: 'Restaurant Name',
    distance: '?',
    distanceUnit: 'm',
    price: '?',
    currency: 'CZK',
};

const Offer = ({ data, onClick = undefined, ...restProps }) => {
    const {
        rating,
        heading,
        description,
        restaurant,
        distance,
        distanceUnit,
        price,
        currency,
    } = { ...initialData, ...data };

    return (
        <Wrapper onClick={onClick} flexGrow={1} margin="0.5em" {...restProps}>
            <Row>
                <Heading>{heading}</Heading>
                {rating && (
                    <RatingBox>
                        <Rating>{rating}</Rating>
                    </RatingBox>
                )}
            </Row>
            <Row>
                <Description>{description}</Description>
            </Row>
            <Row marginTop="1em">
                <Row alignItems="center">
                    <InfoIcon src="/icons/pointer.svg" />
                    <Info>{restaurant}</Info>
                    <InfoIcon src="/icons/feet.svg" />
                    <Info>{`${distance} ${distanceUnit}`}</Info>
                </Row>
                <Column>
                    <Row justifyContent="end" alignItems="end">
                        <Price>{price}</Price>
                        <Currency>{currency}</Currency>
                    </Row>
                </Column>
            </Row>
        </Wrapper>
    );
};

Offer.propTypes = {
    heading: PropTypes.string,
    description: PropTypes.string,
    restaurant: PropTypes.string,
    distance: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    distanceUnit: PropTypes.string,
};

export default Offer;
