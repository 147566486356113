import { Slide } from '../../components/slide/slide.elements';
import LoginForm from '../../forms/login.form';

const Login = () => {
    return (
        <Slide>
            <LoginForm />
        </Slide>
    );
};

export default Login;
