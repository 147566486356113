import { useContext, useState } from 'react';

import { AuthContext } from './contexts/auth.context';
import Layout from './layouts/default.layout';
import Overlay from './components/overlay/overlay.component';
import TopPanel from './components/top-panel/top-panel.component';
import Auth from './views/auth/auth.view';
import Main from './views/main/main.view';
import Menu from './views/menu/menu.view';
import fadeAnimation from './animations/motion/fade.animation';

const App = () => {
    const { isLoggedIn } = useContext(AuthContext);
    const [showMenu, setShowMenu] = useState(false);

    const showOverlay = () => setShowMenu(true);
    const hideOverlay = () => setShowMenu(false);

    return (
        <Layout>
            <TopPanel onMenuButtonClick={showOverlay} />
            <Overlay
                showOverlay={showMenu}
                hideOverlay={hideOverlay}
                backgroundColor="var(--bg-overlay)"
                {...fadeAnimation}
            >
                <Menu />
            </Overlay>
            {!isLoggedIn ? <Auth /> : <Main />}
        </Layout>
    );
};

export default App;
