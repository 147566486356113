import styled from 'styled-components';
import { Logo, MenuButton } from './top-panel.elements';

const TopPanel = styled(({ onMenuButtonClick, ...styles }) => (
    <div {...styles}>
        <Logo data-blur />
        <MenuButton onClick={onMenuButtonClick} data-blur />
    </div>
))`
    width: 100%;
    height: 5rem;
    position: fixed;
    top: 0;
`;

export default TopPanel;
