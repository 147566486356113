import styled from 'styled-components';
import Form from '../form.elements';
import SuggestionList from '../../suggestions-list/suggestions-list.component';
import { forwardRef } from 'react';

const defaultIconPaths = [
    'M63.29,28.82c-1.43-6.2-6.87-10.52-13.23-10.52c-0.36,0-0.72,0.03-1.07,0.05v-5.96c0-0.39-0.23-0.74-0.59-0.9L32.68,4.55c-0.05-0.02-0.11-0.01-0.16-0.03c-0.08-0.02-0.15-0.04-0.23-0.04c-0.08,0-0.16,0.02-0.24,0.04c-0.05,0.01-0.11,0-0.16,0.03l-15.26,6.73L1.38,4.55c-0.3-0.13-0.65-0.11-0.93,0.08C0.17,4.81,0,5.12,0,5.45v37.48c0,0.39,0.23,0.74,0.59,0.9l15.35,6.77c0.03,0.03,0.05,0.07,0.09,0.09c0.16,0.11,0.35,0.16,0.54,0.16c0.03,0,0.05-0.01,0.08-0.02c0.03,0,0.05,0.02,0.08,0.02c0.19,0,0.37-0.05,0.54-0.16c0.04-0.02,0.05-0.07,0.09-0.09L32.28,44l1.93,0.85l9.04,4.1c2.92,5.33,5.74,9.75,5.98,10.13c0.18,0.28,0.49,0.45,0.83,0.45c0.33,0,0.65-0.17,0.83-0.45c0.05-0.08,5.15-8.07,8.84-15.61c1.9-3.88,3.12-7.02,3.63-9.32c0.19-0.87,0.28-1.61,0.28-2.26C63.64,30.85,63.52,29.82,63.29,28.82z M31.3,42.29l-13.61,6.01V12.96l13.61-6V42.29z M61.44,33.73c-0.48,2.15-1.65,5.14-3.47,8.88c-2.76,5.63-6.31,11.53-7.9,14.1c-2.78-4.5-11.61-19.22-11.61-24.81c0-5.7,4.12-10.45,9.54-11.43c0.01,0,0.02,0,0.02,0c0.06,0,0.12-0.02,0.18-0.04c0.61-0.1,1.24-0.17,1.88-0.17c5.44,0,10.09,3.7,11.31,9c0.2,0.86,0.3,1.74,0.3,2.63C61.68,32.39,61.6,33.01,61.44,33.73z',
    'M50.07,23.4c-4.47,0-8.1,3.63-8.1,8.1s3.63,8.1,8.1,8.1c4.48,0,8.1-3.63,8.1-8.1S54.54,23.4,50.07,23.4z',
];

const Wrapper = styled('div')`
    ${SuggestionList} {
        position: absolute;
        top: 100%;
    }
`;

const SearchBar = forwardRef(
    (
        {
            searchValue,
            onChange,
            onClick,
            onFocus,
            onBlur,
            onKeyDown,
            type = 'text',
            placeholder,
            label,
            inputRef,
            iconPaths = defaultIconPaths,
            iconFill = 'var(--text-secondary)',
            onIconClick,
            children,
        },
        ref
    ) => {
        return (
            <Wrapper>
                <Form.Field ref={ref} position="relative" width="100%" maxWidth="400px" margin="0 auto 20px auto">
                    {label && <Form.Label>{label}</Form.Label>}
                    <Form.InputField>
                        <Form.Input
                            type={type}
                            placeholder={placeholder}
                            value={searchValue}
                            onChange={onChange}
                            onClick={onClick}
                            onFocus={onFocus}
                            onBlur={onBlur}
                            onKeyDown={onKeyDown}
                            ref={inputRef}
                        />
                        <Form.SVGIcon
                            viewBox="0 0 64 64"
                            fill={iconFill}
                            onClick={onIconClick}
                            cursor={onIconClick ? 'pointer' : 'default'}
                        >
                            {iconPaths.map((iconPath, index) => (
                                <path key={index} d={iconPath} />
                            ))}
                        </Form.SVGIcon>
                    </Form.InputField>
                    {children}
                </Form.Field>
            </Wrapper>
        );
    }
);
SearchBar.displayName = 'SearchBar';

export default SearchBar;
