import styled, { css } from 'styled-components';
import { compose, space, layout } from 'styled-system';

const Form = styled('form')`
    width: 100%;
`;
Form.Field = styled('div')`
    ${compose(space, layout)}
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    ${({ area }) =>
        area &&
        css`
            grid-area: ${area};
        `};
`;
Form.InputField = styled('div')`
    display: flex;
    width: 100%;
    height: 3.5rem;
    font-size: 1.8rem;
    background-color: var(--bg-secondary);
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid transparent;
    ${({ hasError }) =>
        hasError &&
        css`
            border: 1px solid var(--text-danger);
        `};
`;
Form.Input = styled('input')`
    width: 100%;
    outline: none;
    border: none;
    padding: 0.5rem;
    background-color: transparent;
    font-size: 1.8rem;
    color: var(--text-secondary);
    ::placeholder,
    ::-webkit-input-placeholder {
        color: var(--text-secondary-darker);
    }
    @media (min-width: 600px) {
        font-size: 1.6rem;
    }
`;
Form.Select = styled('select')`
    flex-grow: 1;
    outline: none;
    border: none;
    padding: 5px;
    background-color: transparent;
    color: var(--text-secondary);
`;
Form.Option = styled('option')`
    background-color: var(--bg-secondary);
    color: var(--text-secondary);
`;
Form.Label = styled('label')`
    color: var(--text-primary, #fff);
    margin: 3px 0;
    font-size: 1.5rem;
`;
Form.Icon = styled('img').attrs({
    draggable: false,
})`
    padding: 7px;
`;
Form.SVGIcon = styled('svg')`
    padding: 3px;
    cursor: ${({ cursor }) => (cursor ? cursor : 'default')};
`;
Form.Message = styled('p')`
    color: ${({ type }) => {
        if (type) {
            if (type.toLowerCase() === 'error') return 'var(--text-danger)';
            if (type.toLowerCase() === 'help') return 'var(--text-primary)';
        }
        return 'var(--text-primary)';
    }};
    background-color: var(--bg-secondary);
    position: relative;
    border-radius: 5px;
    opacity: 0.8;
    padding: 3px;
    margin: 3px 0 0 0;
`;
Form.Submit = styled('button').attrs({ type: 'submit' })`
    float: ${({ float }) => (float ? float : 'initial')};
    background-color: var(--bg-secondary);
    color: var(--text-secondary);
    border: none;
    border-radius: 5px;
    padding: 10px;
    cursor: pointer;
`;

export default Form;
