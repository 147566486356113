import { useTranslation } from 'react-i18next';
import Row from '../../../components/row/row.component';
import { LogoutButton } from './menu.elements';

const LogoutSection = ({ onLogout }) => {
    const { t } = useTranslation();

    return (
        <Row justifyContent="center">
            <LogoutButton
                backgroundColor="var(--bg-primary)"
                onClick={onLogout}
            >
                {t('MENU_LOGOUT')}
            </LogoutButton>
        </Row>
    );
};

export default LogoutSection;
