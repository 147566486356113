const pathAnimation = {
    initial: {
        pathLength: 0,
    },
    animate: {
        pathLength: 1,
    },
    exit: {
        pathLength: 0,
    },
};

export default pathAnimation;
