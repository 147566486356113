import { useEffect, createContext } from 'react';
import useLocalStorage from 'use-local-storage';

const initialTheme = {
    'bg-primary': 'linear-gradient(#A0DFD6, #B3D2AE)',
    'bg-secondary': '#fff',
    'bg-secondary-darker': '#eaeaea',
    'bg-overlay': 'rgba(0,0,0,0.4)',
    'bg-overlay-secondary': 'rgba(125,125,125,0.5)',
    'text-primary': '#fff',
    'text-primary-darker': '#333',
    'text-secondary': '#111',
    'text-secondary-darker': '#000',
    'text-danger': '#941832',
};

export const ThemeContext = createContext();
const ThemeContextProvider = ({ children }) => {
    const [theme, setTheme] = useLocalStorage('theme', initialTheme);

    useEffect(() => {
        document.documentElement.style.cssText = `
        --bg-primary: ${theme['bg-primary']};
        --bg-secondary: ${theme['bg-secondary']};
        --bg-secondary-darker: ${theme['bg-secondary-darker']};
        --bg-overlay: ${theme['bg-overlay']};
        --bg-overlay-secondary: ${theme['bg-overlay-secondary']};
        --text-primary: ${theme['text-primary']};
        --text-primary-darker: ${theme['text-primary-darker']};
        --text-secondary: ${theme['text-secondary']};
        --text-secondary-darker: ${theme['text-secondary-darker']};
        --text-danger: ${theme['text-danger']};
      `;
        return () => {
            document.documentElement.style.cssText = null;
        };
    }, [theme]);

    return (
        <ThemeContext.Provider value={{ theme, setTheme, initialTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};

export default ThemeContextProvider;
