import styled from 'styled-components';
import { compose, space, flexbox } from 'styled-system';

export const Wrapper = styled('div')`
    ${compose(space, flexbox)}
    background-color: var(--bg-secondary);
    color: var(--text-secondary);
    border-radius: 15px;
    padding: 1em;
    transition: 0.3s;
    overflow: hidden;
    cursor: pointer;
    opacity: 0.9;
    &:hover {
        opacity: 1;
    }

    & * {
        pointer-events: none;
    }
`;
export const RatingBox = styled('div')`
    display: flex;
    min-width: 30px;
    height: 30px;
    position: relative;
    background: url('/icons/star.svg') no-repeat center;
`;
export const Rating = styled('span')`
    display: block;
    width: 100%;
    color: #fff;
    text-align: center;
    line-height: 30px;
`;
export const Heading = styled('h2')`
    width: 100%;
    font-size: 1.8rem;
`;
export const Description = styled('p')`
    font-size: 1.4rem;
`;
export const Info = styled('span')`
    font-size: 1.4rem;
    padding-right: 5px;
`;
export const InfoIcon = styled('img').attrs({ draggable: false })`
    height: 15px;
    width: 15px;
    box-sizing: content-box;
    padding-right: 5px;
`;
export const Price = styled('p')`
    font-size: 1.6rem;
    padding-right: 0.1em;
`;
export const Currency = styled('p')`
    font-size: 1.4rem;
`;
