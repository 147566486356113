import { useState, useRef, useContext, useMemo } from 'react';
import { motion } from 'framer-motion';
import useOnclickOutside from 'react-cool-onclickoutside';
import SearchBar from '../../../components/form/components/search-bar.component';
import SuggestionsList from '../../../components/suggestions-list/suggestions-list.component';
import Suggestion from '../../../components/suggestion/suggestion.component';
import Controls from '../../../components/controls/controls.component';
import Overlay from '../../../components/overlay/overlay.component';
import { IngredientsContext } from '../../../contexts/ingredients.context';
import scaleAnimation from '../../../animations/motion/scale.animation';
import { useTranslation } from 'react-i18next';

const IngredientSearchBarSection = () => {
    const { t } = useTranslation();
    const { ingredients, ingredientMethods } = useContext(IngredientsContext);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [clickedSuggestion, setClickedSuggestion] = useState(null);
    const [searchBoxIndex, setSearchBoxIndex] = useState(0);
    const ingredientSearchBarInputRef = useRef(null);
    const ingredientSuggestionListRef = useRef(null);
    const [ingredientSearchBarValue, setIngredientSearchBarValue] = useState('');

    const ingredientSearchBarRef = useRef(null);

    const handleOnIngredientSearchBarChange = (e) => {
        setIngredientSearchBarValue(e.target.value);
        setShowSuggestions(true);
    };

    const handleSearchBoxKeyControls = (e) => {
        switch (e.key) {
            case 'ArrowDown':
                e.preventDefault();
                setShowSuggestions(true);
                showSuggestions && setSearchBoxIndex((i) => (i < ingredientSuggestions.length ? i + 1 : i));
                break;
            case 'ArrowUp':
                e.preventDefault();
                setShowSuggestions(true);
                setSearchBoxIndex((i) => (i > 0 ? i - 1 : 0));
                break;
            case 'Escape':
                setShowSuggestions(false);
                ingredientSearchBarInputRef.current.blur();
                break;
            case 'Enter':
                if (ingredientSuggestionListRef.current) {
                    ingredientSuggestionListRef.current.children[searchBoxIndex].click();
                    ingredientSearchBarInputRef.current.blur();
                }
                break;
            default:
                return;
        }
    };

    const handleOnAddSuggestionClick = () => {
        setClickedSuggestion({
            id: 'new-ingredient',
            name: ingredientSearchBarValue,
            value: ingredientSearchBarValue,
            isNew: true,
        });
    };

    const handleOnSuggestionClick = (e, suggestion) => {
        setClickedSuggestion(suggestion);
    };

    const handleHideOverlay = () => {
        setClickedSuggestion(null);
        setShowSuggestions(true);
        ingredientSearchBarInputRef.current.focus();
    };

    const handleControlButtonClick = async (type, isNew = false) => {
        const update = async (type, keywordId, name) => {
            switch (type) {
                case 'LIKE':
                    return await ingredientMethods.like({ keywordId, name });
                case 'DISLIKE':
                    return await ingredientMethods.dislike({ keywordId, name });
                default:
                    return;
            }
        };

        if (isNew) {
            const { keywordId } = await ingredientMethods.addNew({
                name: clickedSuggestion?.name,
                lang: 'cs',
            });
            await update(type, keywordId, clickedSuggestion.name);
        } else {
            await update(type, clickedSuggestion.id, clickedSuggestion.name);
        }
        setIngredientSearchBarValue('');
        setClickedSuggestion(null);
    };

    const ingredientSuggestions = useMemo(
        () =>
            ingredients
                .filter(({ name }) =>
                    name
                        .toLowerCase()
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                        .includes(
                            ingredientSearchBarValue
                                .toLowerCase()
                                .normalize('NFD')
                                .replace(/[\u0300-\u036f]/g, '')
                        )
                )
                .slice(0, 10)
                .map(({ id, name }) => ({ id, name, value: name })),
        [ingredientSearchBarValue, ingredients]
    );

    const ingredientControls = {
        controls: [
            {
                icon: '/icons/angry_smile.png',
                onClick: () => handleControlButtonClick('DISLIKE', clickedSuggestion?.isNew),
            },
            {
                icon: '/icons/love_smile.png',
                onClick: () => handleControlButtonClick('LIKE', clickedSuggestion?.isNew),
            },
        ],
        options: {
            animateButtons: {
                ...scaleAnimation,
                animate: {
                    ...scaleAnimation.animate,
                    transition: { delay: 0.3 },
                },
            },
        },
    };

    useOnclickOutside(
        () => {
            setShowSuggestions(false);
        },
        { refs: [ingredientSearchBarRef] }
    );

    return (
        <SearchBar
            ref={ingredientSearchBarRef}
            inputRef={ingredientSearchBarInputRef}
            label={t('SLIDE_ACCOUNT_INGREDIENTS_LABEL')}
            searchValue={ingredientSearchBarValue}
            placeholder={t('SLIDE_ACCOUNT_INGREDIENTS_PLACEHOLDER')}
            onFocus={() => !!ingredientSearchBarValue.length && setShowSuggestions(true)}
            onChange={handleOnIngredientSearchBarChange}
            onKeyDown={handleSearchBoxKeyControls}
        >
            {showSuggestions && !!ingredients.length && (
                <SuggestionsList
                    ref={ingredientSuggestionListRef}
                    suggestions={ingredientSuggestions}
                    onSuggestionClick={handleOnSuggestionClick}
                    selectedSuggestionIndex={searchBoxIndex}
                >
                    {!!ingredientSearchBarValue && (
                        <Suggestion onClick={handleOnAddSuggestionClick}>{ingredientSearchBarValue}</Suggestion>
                    )}
                </SuggestionsList>
            )}
            <Overlay
                showOverlay={clickedSuggestion?.id}
                hideOverlay={handleHideOverlay}
                blurElement={document.getElementById('account-slide')}
                style={{
                    maxWidth: '400px',
                    margin: '0 auto',
                    position: 'relative',
                }}
            >
                <Suggestion
                    as={motion.div}
                    {...scaleAnimation}
                    initial={{
                        ...scaleAnimation.initial,
                        marginTop: '20vh',
                    }}
                >
                    {clickedSuggestion?.value}
                </Suggestion>
                <Controls {...ingredientControls} />
            </Overlay>
        </SearchBar>
    );
};

export default IngredientSearchBarSection;
