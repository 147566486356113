import { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import RegisterSlide from '../../slides/register/register.slide';
import LoginSlide from '../../slides/login/login.slide';
import { useTranslation } from 'react-i18next';
import SlideNavigation from './components/slide-navigation.component';

const Auth = () => {
    const { t } = useTranslation();
    const [slideIndex, setSlideIndex] = useState(0);

    const links = [
        {
            index: 0,
            label: t('FORM_REGISTER'),
        },
        {
            index: 1,
            label: t('FORM_LOGIN'),
        },
    ];

    const handleLinkClick = (link) => setSlideIndex(link.index);

    return (
        <div>
            <SlideNavigation
                links={links}
                onLinkClick={handleLinkClick}
                activeLink={slideIndex}
            />
            <SwipeableViews
                index={slideIndex}
                onChangeIndex={(index) => setSlideIndex(index)}
            >
                <RegisterSlide />
                <LoginSlide />
            </SwipeableViews>
        </div>
    );
};

export default Auth;
