import React from 'react';
import ReactDOM from 'react-dom';
import './i18n';
import AuthContextProvider from './contexts/auth.context';
import LocationContextProvider from './contexts/location.context';
import ThemeContextProvider from './contexts/theme.context';
import './index.css';
import App from './App';

ReactDOM.render(
    <React.StrictMode>
        <LocationContextProvider>
            <AuthContextProvider>
                <ThemeContextProvider>
                    <App />
                </ThemeContextProvider>
            </AuthContextProvider>
        </LocationContextProvider>
    </React.StrictMode>,
    document.getElementById('root')
);
//reportWebVitals();
