import { motion } from "framer-motion";
import { Button, ButtonText, ButtonIcon } from "./controls.elements";
import Row from "../row/row.component";

const Controls = ({ controls = [], options = {} }) => {
  return (
    <Row>
      {controls.map((control, index) => (
        <Button
          key={index}
          as={options?.animateButtons ? motion.button : "button"}
          initial={options?.animateButtons?.initial ? options?.animateButtons?.initial : null}
          animate={options?.animateButtons?.animate ? options?.animateButtons?.animate : null}
          exit={options?.animateButtons?.exit ? options?.animateButtons?.exit : null}
          transition={options?.animateButtons?.transition ? options?.animateButtons?.transition : null}
          onClick={control?.onClick}
        >
          {control?.icon && <ButtonIcon src={control?.icon} />}
          {control?.text && <ButtonText>{control?.text}</ButtonText>}
        </Button>
      ))}
    </Row>
  );
};

export default Controls;
