import { useContext, useState } from 'react';
import { motion } from 'framer-motion';
import Slide from '../../components/slide/slide.elements';
import Offer from '../../components/offer/offer.component';
import Overlay from '../../components/overlay/overlay.component';
import Controls from '../../components/controls/controls.component';
import { OffersContext } from '../../contexts/offers.context';
import scaleAnimation from '../../animations/motion/scale.animation';
import { useTranslation } from 'react-i18next';
import OffersList from '../../components/offers-list/offers-list.component';
import fadeAnimation from '../../animations/motion/fade.animation';

const Favorite = () => {
    const { t } = useTranslation();
    const { favoriteOffers, offerMethods, favoriteOfferMethods } =
        useContext(OffersContext);
    const [clickedOffer, setClickedOffer] = useState(null);

    const offerControls = {
        controls: [
            {
                icon: '/icons/angry_smile.png',
                onClick: () =>
                    favoriteOfferMethods
                        .dislike(clickedOffer.id)
                        .then(() => setClickedOffer(null)),
            },
            {
                text: t('CONTROLS_NAVIGATE'),
                onClick: () => offerMethods.navigate(clickedOffer),
            },
            {
                text: t('CONTROLS_ORDER'),
                onClick: () => offerMethods.order(clickedOffer),
            },
        ],
        options: {
            animateButtons: {
                ...scaleAnimation,
                animate: {
                    ...scaleAnimation.animate,
                    transition: { delay: 0.3 },
                },
            },
        },
    };

    return (
        <Slide id="favorite-slide">
            <Slide.Message>{t('SLIDE_FAVORITE_MESSAGE')}</Slide.Message>
            <OffersList
                offers={favoriteOffers}
                onOfferClick={setClickedOffer}
                clickedOffer={clickedOffer}
            />
            <Overlay
                blurElement={document.getElementById('favorite-slide')}
                showOverlay={clickedOffer?.id}
                hideOverlay={() => setClickedOffer(null)}
                {...fadeAnimation}
                exit={{ ...fadeAnimation.exit, pointerEvents: 'none' }}
                style={{
                    maxWidth: '600px',
                    margin: '0 auto',
                    position: 'relative',
                }}
            >
                <Offer
                    as={motion.div}
                    data={clickedOffer}
                    {...scaleAnimation}
                    initial={{
                        ...scaleAnimation.initial,
                        opacity: 1,
                        marginTop: '20vh',
                    }}
                    transition={{
                        duration: 0.2,
                    }}
                />
                <Controls {...offerControls} />
            </Overlay>
        </Slide>
    );
};

export default Favorite;
