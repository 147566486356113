import { useContext } from 'react';
import styled from 'styled-components';
import Slide from '../../components/slide/slide.elements';

import { IngredientsContext } from '../../contexts/ingredients.context';
import scaleAnimation from '../../animations/motion/scale.animation';
import { AnimatePresence, motion } from 'framer-motion';

import Row from '../../components/row/row.component';
import Column from '../../components/column/column.component';
import { useTranslation } from 'react-i18next';
import IngredientSearchBarSection from './sections/ingredient-search-bar.section';

const IngredientsColumn = styled(Column)`
    width: 100%;
    max-width: 100%;
    overflow: hidden;
`;

IngredientsColumn.Heading = styled('div')`
    width: 100%;
    margin-bottom: 10px;
    word-break: break-all;
`;
IngredientsColumn.Heading.Text = styled('span')`
    display: block;
    color: var(--text-primary);
    font-size: 1.4rem;
    text-align: center;
`;
IngredientsColumn.Heading.Icon = styled('img')`
    display: block;
    width: 25px;
    height: 25px;
    object-fit: contain;
    margin: 0 auto 5px auto;
`;

const SeparateLine = styled('div')`
    min-height: 100%;
    width: 1px;
    background-color: #fff;
`;

const IngredientsList = styled('div')`
    display: flex;
    flex-wrap: wrap;
    padding: 0 10px;
    justify-content: center;
`;
const Ingredient = styled('div')`
    border: 1px solid #fff;
    border-radius: 5px;
    display: flex;
    align-items: center;
    margin: 2px;
`;
Ingredient.Name = styled('span')`
    font-size: 1.4rem;
    color: #fff;
    padding: 0 0.5em;
    word-break: break-all;
`;
Ingredient.CloseButton = styled('button')`
    height: 20px;
    width: 20px;
    background: transparent url('/icons/delete_white.svg') no-repeat center;
    background-size: 50%;
    border: none;
    cursor: pointer;
`;

const Account = () => {
    const { t } = useTranslation();
    const { likedIngredients, dislikedIngredients, ingredientMethods } = useContext(IngredientsContext);

    const handleRemoveButtonClick = (keywordId, status) => {
        ingredientMethods.remove({ keywordId, status });
    };

    return (
        <Slide id="account-slide">
            <IngredientSearchBarSection />
            <Row>
                <IngredientsColumn>
                    <Row>
                        <IngredientsColumn.Heading>
                            <IngredientsColumn.Heading.Icon src="/icons/angry_smile.png" />
                            <IngredientsColumn.Heading.Text>
                                {t('SLIDE_ACCOUNT_INGREDIENTS_COLUMN_HEADING_DISLIKE')}
                            </IngredientsColumn.Heading.Text>
                        </IngredientsColumn.Heading>
                    </Row>
                    <IngredientsList>
                        <AnimatePresence>
                            {dislikedIngredients.map(({ _id: id, keyword, name }) => (
                                <Ingredient as={motion.div} key={'d-i_' + id} {...scaleAnimation}>
                                    <Ingredient.Name>{name || keyword}</Ingredient.Name>
                                    <Ingredient.CloseButton
                                        onClick={() => handleRemoveButtonClick(keyword, 'DISLIKED')}
                                    />
                                </Ingredient>
                            ))}
                        </AnimatePresence>
                    </IngredientsList>
                </IngredientsColumn>
                <SeparateLine />
                <IngredientsColumn>
                    <Row>
                        <IngredientsColumn.Heading>
                            <IngredientsColumn.Heading.Icon src="/icons/love_smile.png" />
                            <IngredientsColumn.Heading.Text>
                                {t('SLIDE_ACCOUNT_INGREDIENTS_COLUMN_HEADING_LIKE')}
                            </IngredientsColumn.Heading.Text>
                        </IngredientsColumn.Heading>
                    </Row>
                    <IngredientsList>
                        <AnimatePresence>
                            {likedIngredients.map(({ _id: id, keyword, name }) => (
                                <Ingredient as={motion.div} key={'l-i_' + id} {...scaleAnimation}>
                                    <Ingredient.Name>{name || keyword}</Ingredient.Name>
                                    <Ingredient.CloseButton onClick={() => handleRemoveButtonClick(keyword, 'LIKED')} />
                                </Ingredient>
                            ))}
                        </AnimatePresence>
                    </IngredientsList>
                </IngredientsColumn>
            </Row>
        </Slide>
    );
};

export default Account;
