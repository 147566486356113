import { motion } from 'framer-motion';
import { Fragment } from 'react';
import styled from 'styled-components';
import { position } from 'styled-system';
import { Wrapper, Button, SVGIcon } from './bottom-panel.elements';
import fadeAnimation from '../../animations/motion/fade.animation';
import pathAnimation from '../../animations/motion/path.animation';

const BottomPanel = styled(
    ({ links, activeLink, onLinkClick = () => null, ...styles }) => {
        return (
            <Wrapper {...styles}>
                {links.map(({ name, iconPaths, activeFill }, index) => (
                    <Button
                        key={index}
                        onClick={() => onLinkClick(index)}
                        active={index === activeLink}
                    >
                        {!iconPaths ? (
                            name
                        ) : (
                            <SVGIcon
                                as={motion.svg}
                                viewBox="0 0 64 64"
                                stroke={
                                    index === activeLink ? activeFill : '#fff'
                                }
                                strokeWidth="2"
                                fill="transparent"
                            >
                                {iconPaths.map((iconPath) => (
                                    <Fragment key={index}>
                                        <motion.path
                                            d={iconPath}
                                            {...pathAnimation}
                                            transition={{
                                                pathLength: {
                                                    duration: 1.5,
                                                },
                                            }}
                                        />
                                        <motion.path
                                            d={iconPath}
                                            fill={
                                                index === activeLink
                                                    ? activeFill
                                                    : '#fff'
                                            }
                                            {...fadeAnimation}
                                            transition={{ delay: 1.5 }}
                                        />
                                    </Fragment>
                                ))}
                            </SVGIcon>
                        )}
                    </Button>
                ))}
            </Wrapper>
        );
    }
)`
    ${position}
    transform: ${({ transform }) => (transform ? transform : 'none')};
`;

export default BottomPanel;
