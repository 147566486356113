import { useTranslation } from 'react-i18next';
import Slide from '../../components/slide/slide.elements';
import OfferModelSelectionSection from './sections/offer-model-selection.section.dev';
import LocationSection from './sections/location.section';
import OffersSection from './sections/offers.section';

const Home = () => {
    const { t } = useTranslation();

    return (
        <Slide id="home-slide">
            {process.env.REACT_APP_ENV === 'DEV' && (
                <OfferModelSelectionSection />
            )}
            <LocationSection />
            <Slide.Message>{t('SLIDE_HOME_MESSAGE')}</Slide.Message>
            <OffersSection />
        </Slide>
    );
};

export default Home;
