import styled from 'styled-components';
import { motion } from 'framer-motion';
import Form from '../../../components/form/form.elements';

const AnimatedErrorMessage = styled(Form.Message).attrs({
    as: motion.p,
    type: 'error',
    initial: {
        opacity: 0,
    },
    animate: {
        opacity: 0.8,
    },
    exit: {
        opacity: 0,
    },
})``;

export default AnimatedErrorMessage;
