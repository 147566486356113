import { useState, useRef, useEffect, useContext } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useTranslation } from 'react-i18next';
import { LocationContext } from '../../../contexts/location.context';
import SearchBar from '../../../components/form/components/search-bar.component';
import SuggestionsList from '../../../components/suggestions-list/suggestions-list.component';

const LocationSection = () => {
    const { t } = useTranslation();
    const { location, getPosition, getAddress, setLocation, clearLocation } =
        useContext(LocationContext);
    const [addressSuggestions, setAddressSuggestions] = useState([]);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [searchBoxIndex, setSearchBoxIndex] = useState(0);
    const locationSearchBarRef = useOnclickOutside(() => {
        setShowSuggestions(false);
    });
    const [locationSearchBarValue, setLocationSearchBarValue] = useState('');

    const [isWriting, setIsWriting] = useState(false);
    const addressSuggestionListRef = useRef(null);

    const handleOnSearchBarIconClick = () => {
        navigator.geolocation.getCurrentPosition(
            (pos) => {
                getAddress(pos.coords.longitude, pos.coords.latitude).then(
                    ({
                        address: { road, house_number, city, suburb, postcode },
                        lat,
                        lon,
                    }) => {
                        const address = `${road} ${house_number}, ${city}, ${suburb}, ${postcode}`;
                        setLocation({
                            address,
                            lat,
                            lon,
                        });
                        setLocationSearchBarValue(address);
                    }
                );
            },
            () => {
                setLocationSearchBarValue('Nepodařilo se načíst polohu.');
            }
        );
    };

    const handleOnSearchBarValueChange = (e) => {
        setIsWriting(true);
        setLocationSearchBarValue(e.target.value);
        setShowSuggestions(true);
    };

    const handleSearchBoxKeyControls = (e) => {
        switch (e.key) {
            case 'ArrowDown':
                e.preventDefault();
                setShowSuggestions(true);
                showSuggestions &&
                    setSearchBoxIndex((i) =>
                        i < addressSuggestions.length - 1 ? i + 1 : i
                    );
                break;
            case 'ArrowUp':
                e.preventDefault();
                setShowSuggestions(true);
                setSearchBoxIndex((i) => (i > 0 ? i - 1 : 0));
                break;
            case 'Escape':
                setShowSuggestions(false);
                break;
            case 'Enter':
                addressSuggestionListRef.current.children[
                    searchBoxIndex
                ].click();
                break;
            default:
                return;
        }
    };

    const handleOnSuggestionClick = (e, { value: address, lat, lon }) => {
        setLocation({ address, lat, lon });
        setShowSuggestions(false);
    };

    useEffect(() => {
        if (location.address) {
            setLocationSearchBarValue(location.address);
        }
    }, [location]);

    useEffect(() => {
        const delayTimeout = setTimeout(() => {
            if (isWriting) {
                setIsWriting(false);
                if (!locationSearchBarValue.length) {
                    setAddressSuggestions([]);
                    clearLocation();
                }
                if (locationSearchBarValue.length > 2) {
                    getPosition(locationSearchBarValue).then((suggestions) => {
                        setAddressSuggestions(
                            suggestions.map(({ label, lat, lon }) => ({
                                value: label.replace(/\s+/g, ' ').trim(),
                                lat,
                                lon,
                            }))
                        );
                    });
                }
            }
        }, 600);
        return () => clearTimeout(delayTimeout);
        // eslint-disable-next-line
    }, [locationSearchBarValue]);

    useEffect(() => {
        setSearchBoxIndex(0);
    }, [addressSuggestions]);

    return (
        <SearchBar
            ref={locationSearchBarRef}
            label={t('SLIDE_HOME_ADDRESS_LABEL')}
            searchValue={locationSearchBarValue}
            placeholder={t('SLIDE_HOME_ADDRESS_PLACEHOLDER')}
            onChange={handleOnSearchBarValueChange}
            onFocus={() => setShowSuggestions(true)}
            onKeyDown={handleSearchBoxKeyControls}
            onIconClick={handleOnSearchBarIconClick}
        >
            {showSuggestions && !!addressSuggestions.length && (
                <SuggestionsList
                    ref={addressSuggestionListRef}
                    suggestions={addressSuggestions}
                    onSuggestionClick={handleOnSuggestionClick}
                    selectedSuggestionIndex={searchBoxIndex}
                />
            )}
        </SearchBar>
    );
};

export default LocationSection;
