import { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../contexts/auth.context';
import { OverlayContext } from '../../components/overlay/overlay.component';
import { Heading } from './components/menu.elements';
import LanguageButtonsList from './components/language-buttons-list.component';
import ThemeButtonsList from './components/theme-buttons-list.component';
import LogoutSection from './components/logout-section.component';
import themes from '../../data/themes.json';
import locales from '../../data/locales.json';

const Menu = styled(({ ...styles }) => {
    const { t } = useTranslation();
    const { hideOverlay } = useContext(OverlayContext);
    const { isLoggedIn, clearUser } = useContext(AuthContext);

    const handleLogout = () => {
        clearUser();
        hideOverlay();
    };

    return (
        <div {...styles}>
            <Heading>{t('MENU_LANGUAGE_SELECT_HEADING')}</Heading>
            <LanguageButtonsList locales={locales} />
            <Heading>{t('MENU_THEME_SELECT_HEADING')}</Heading>
            <ThemeButtonsList themes={themes} />
            {isLoggedIn && <LogoutSection onLogout={handleLogout} />}
        </div>
    );
})`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: max-content;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
`;

export default Menu;
