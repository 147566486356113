// Create user
// Props: {username: string, email: string, password: string, telephone: string}
// Response: Success / Error message
export const POST_USER = (data) =>
    fetch('https://api.hungerbot.cz/v1/user', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
        .then((res) => res.json())
        .catch((err) => err);

// Log user into system
// Props: {username: string, password: string}
// Response: User's data
export const POST_USER_LOGIN = (data) =>
    fetch('https://api.hungerbot.cz/v1/user/login', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    })
        .then((res) => res.json())
        .catch((err) => err);

// Refresh User's token
// Props: {sessionId: string}
// Response: New Token
export const GET_USER_REFRESH = ({ sessionId }, token) =>
    fetch(`https://api.hungerbot.cz/v1/user/refresh&sessionId=${sessionId}`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then((res) => res.json())
        .catch((err) => err);

// Log user out of system
// Props: {userId: string, sessionId: string}
// Response: Success / Error message
export const GET_USER_LOGOUT = (data, token) =>
    fetch(
        `https://api.hungerbot.cz/v1/user/logout?userId=${data.userId}&sessionId=${data.sessionId}`,
        {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    )
        .then((res) => res.json())
        .catch((err) => err);
