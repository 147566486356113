import { useContext } from 'react';
import { OffersContext } from '../../../contexts/offers.context';
import Form from '../../../components/form/form.elements';

const OfferModelSelectionSection = () => {
    const { models, model, setModel } = useContext(OffersContext);

    return (
        <Form.Field>
            <Form.Label>DEV: Offer's model</Form.Label>
            <Form.InputField>
                <Form.Select
                    defaultValue={model}
                    onChange={(e) => setModel(e.target.value)}
                >
                    {models.map((_model) => (
                        <Form.Option key={_model} value={_model}>
                            {_model}
                        </Form.Option>
                    ))}
                </Form.Select>
            </Form.InputField>
        </Form.Field>
    );
};

export default OfferModelSelectionSection;
