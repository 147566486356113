import styled from 'styled-components';
import { compose, space, flexbox } from 'styled-system';

const Row = styled('div')`
    ${compose(space, flexbox)}
    display: flex;
    width: 100%;
    align-items: stretch;
`;

export default Row;
