import { useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import FavoriteSlide from '../../slides/favorite/favorite.slide';
import HomeSlide from '../../slides/home/home.slide';
import AccountSlide from '../../slides/account/account.slide';
import BottomPanel from '../../components/bottom-panel/bottom-panel.component';
import OffersContextProvider from '../../contexts/offers.context';
import IngredientsContextProvider from '../../contexts/ingredients.context';

const slideList = [
    {
        name: 'Favorite',
        Component: FavoriteSlide,
        iconPaths: [
            'M49.51,3.94c-10.2,0-15.46,6.32-17.5,9.61c-0.57-0.94-1.43-2.12-2.56-3.33c-0.05-0.06-0.1-0.12-0.16-0.18c-0.01-0.01-0.03-0.03-0.04-0.04c-0.1-0.11-0.22-0.21-0.33-0.32c-0.3-0.3-0.61-0.59-0.92-0.85c-0.08-0.07-0.14-0.14-0.22-0.2c-0.02-0.02-0.05-0.02-0.07-0.04c-5.1-4.28-10.38-4.47-10.38-4.47s0,0,0,0c-0.93-0.11-1.87-0.18-2.84-0.18C3.81,3.94,0,12.88,0,21.25c0,9.46,5.47,19.17,15.83,28.06c7.74,6.65,15.35,10.47,15.67,10.63c0.16,0.08,0.33,0.12,0.49,0.12s0.34-0.04,0.49-0.12c0.32-0.16,7.94-3.98,15.67-10.63C58.53,40.41,64,30.71,64,21.25C64,12.88,60.19,3.94,49.51,3.94z',
        ],
        activeFill: 'rgb(211, 21, 81)',
    },
    {
        name: 'Home',
        Component: HomeSlide,
        iconPaths: [
            'M25.6,59.2V40h12.8v19.2h16V33.6H64L32,4.8L0,33.6h9.6v25.6H25.6z',
        ],
        activeFill: 'rgb(196, 208, 176)',
    },
    {
        name: 'Account',
        Component: AccountSlide,
        iconPaths: [
            'M40.03,41.91c-0.13-0.34-0.29-1.04-0.36-1.88c1.23-1.38,3.96-4.81,5.02-9.28c1.11-0.55,2.02-1.69,2.59-3.23c0.78-2.13,0.86-5.14-0.68-7.13v-0.01c0.29-2.71,1.07-9.9-3.2-14.66c-2.56-2.85-6.43-4.3-11.51-4.3c-5.07,0-8.94,1.45-11.5,4.3c-4.26,4.75-3.49,11.93-3.2,14.67c-1.54,1.99-1.46,5-0.68,7.13c0.56,1.54,1.48,2.68,2.59,3.23c1.07,4.47,3.79,7.9,5.02,9.28c-0.07,0.81-0.22,1.54-0.36,1.88C19.35,42.85,0,50.45,0,60.76v1.82h63.79v-1.82C63.79,50.45,44.44,42.85,40.03,41.91z',
        ],
        activeFill: 'rgb(40, 119, 182)',
    },
];

const Main = () => {
    const [slideIndex, setSlideIndex] = useState(1);

    return (
        <main>
            <OffersContextProvider>
                <IngredientsContextProvider>
                    <SwipeableViews
                        index={slideIndex}
                        onChangeIndex={(index) => setSlideIndex(index)}
                        containerStyle={{ height: '90vh' }}
                        slideClassName="hide-scrollbar"
                    >
                        {slideList.map(({ Component }, index) => (
                            <Component key={`slide-${index}`} />
                        ))}
                    </SwipeableViews>
                </IngredientsContextProvider>
            </OffersContextProvider>
            <BottomPanel
                links={slideList}
                activeLink={slideIndex}
                onLinkClick={(index) => setSlideIndex(index)}
                position="fixed"
                top="85vh"
                left="50%"
                transform="translateX(-50%)"
                data-blur
            />
        </main>
    );
};

export default Main;
