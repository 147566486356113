import styled from 'styled-components';

export const Wrapper = styled('nav')`
    display: flex;
    background-color: rgba(125, 125, 125, 0.5);
    width: max-content;
    border-radius: 15px;
`;
export const Button = styled('button')`
    background-color: ${({ active }) =>
        !!active ? 'var(--bg-secondary)' : 'transparent'};
    border: none;
    border-radius: 15px;
    outline: none;
    width: 60px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: 0.2s;
`;
export const SVGIcon = styled('svg')`
    padding: 10px 0 7px 0;
    box-sizing: content-box;
    height: 20px;
`;

export default {
    Wrapper,
    Button,
    SVGIcon,
};
