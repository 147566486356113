// Get keyword list for autocomplete
// Props: token: string
// Response: Array of keywords
export const GET_KEYWORD = ({ lang }, token) =>
    fetch(`https://api.hungerbot.cz/v1/keyword?lang=${lang}`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then((res) => res.json())
        .then(({ data }) => data.map((keyword) => ({ id: keyword._id, name: keyword.name })))
        .catch((err) => console.log(err));

// Adds new keyword for voting process, voted keywords are submitted to global list
// Props: data: {name: string, lang: string}
// Response: keywordId: string
export const POST_KEYWORD = ({ name, lang }, token) =>
    fetch('https://api.hungerbot.cz/v1/keyword', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ name, lang }),
    })
        .then((res) => res.json())
        .catch((err) => err);

// Get all weight for user
// Props: token: string
// Response: {keywordId: string, weight: number}
export const GET_KEYWORD_WEIGHT = (token) =>
    fetch(`https://api.hungerbot.cz/v1/keyword/weight`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then((res) => res.json())
        .catch((err) => err);

// Add / edit keyword weight for user
// Props: data: {keywordId: string, weight: number}, token: string
// Response: Success / Error message
export const POST_KEYWORD_WEIGHT = ({ keywordId, weight, name, lang }, token) =>
    fetch('https://api.hungerbot.cz/v1/keyword/weight', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ keywordId, weight, name, lang }),
    })
        .then((res) => res.json())
        .catch((err) => err);

// Delete keyword weight for user
// Props: data: {keywordId: string}, token: string
// Response: Success / Error message
export const DELETE_KEYWORD_WEIGHT = ({ keywordId }, token) =>
    fetch(`https://api.hungerbot.cz/v1/keyword/weight?keywordId=${keywordId}`, {
        method: 'DELETE',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then((res) => res.json())
        .catch((err) => err);
