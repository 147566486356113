import styled from 'styled-components';

export const Slide = styled('div')`
    max-width: 600px;
    min-height: 90vh;
    margin: 0 auto;
    padding: 0 10px;
`;

export const Message = styled('p')`
    color: #fff;
    font-size: 1.6rem;
    text-align: center;
`;

Slide.Message = Message;

export default Slide;
