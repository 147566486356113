import { createContext, useEffect } from 'react';
import useLocalStorage from 'use-local-storage';

export const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
    const [token, setToken] = useLocalStorage('token', null);
    const [sessionId, setSessionId] = useLocalStorage('sessionId', null);
    const [userId, setUserId] = useLocalStorage('userId', null);

    const isLoggedIn = (() => !!(token?.value && sessionId && userId))();

    const saveToken = (token) => {
        if (token) {
            setToken({ createdAt: new Date().getTime(), value: token });
        } else {
            setToken(null);
        }
    };

    const saveUser = ({ token, sessionId, userId, username, email }) => {
        saveToken(token);
        setSessionId(sessionId);
        setUserId(userId);
        localStorage.setItem('username', JSON.stringify(username));
        localStorage.setItem('email', JSON.stringify(email));
    };

    const clearUser = () => {
        saveToken();
        setSessionId(null);
        setUserId(null);
        localStorage.clear();
    };

    useEffect(() => {
        if (token?.createdAt + 5400000 < new Date().getTime()) {
            /*GET_USER_REFRESH({ sessionId }, token.value).then((data) => {
                console.log(data);
            });*/
            clearUser();
            window.location.reload();
        }
        // eslint-disable-next-line
    }, [token]);
    return (
        <AuthContext.Provider
            value={{
                token: token?.value,
                saveToken,
                sessionId,
                saveUser,
                clearUser,
                isLoggedIn,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContextProvider;
