import { createPortal } from 'react-dom';
import { AnimatePresence, motion } from 'framer-motion';
import styled from 'styled-components';
import { createContext, useEffect } from 'react';
import { BLUR_STRENGTH } from '../../constants/constants';

const OverlayWrapper = styled(motion.div)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: ${({ backgroundColor }) =>
        backgroundColor || 'transparent'};
    margin: 0;
    padding: 0;
`;

const OverlayContent = styled('div')``;

export const OverlayContext = createContext();

const Overlay = ({
    children,
    showOverlay = false,
    hideOverlay = undefined,
    blur = BLUR_STRENGTH,
    blurElement = document.getElementById('root'),
    style,
    backgroundColor,
    onClick = undefined,
    ...restProps
}) => {
    useEffect(() => {
        if (!!showOverlay) {
            try {
                blurElement.style.filter = `blur(${blur}px)`;
                Array.from(document.querySelectorAll('*[data-blur]')).forEach(
                    (element) => {
                        element.style.filter = `blur(${blur}px)`;
                    }
                );
            } catch {}
        }
        return () => {
            try {
                blurElement.style.filter = null;
                Array.from(document.querySelectorAll('*[data-blur]')).forEach(
                    (element) => {
                        element.style.filter = null;
                    }
                );
            } catch {}
        };
        // eslint-disable-next-line
    }, [showOverlay, blur]);
    return createPortal(
        <AnimatePresence>
            {showOverlay && (
                <OverlayContext.Provider value={{ hideOverlay: hideOverlay }}>
                    <OverlayWrapper
                        backgroundColor={backgroundColor}
                        onClick={hideOverlay}
                        {...restProps}
                    >
                        <OverlayContent
                            onClick={(e) => e.stopPropagation()}
                            style={style}
                        >
                            {children}
                        </OverlayContent>
                    </OverlayWrapper>
                </OverlayContext.Provider>
            )}
        </AnimatePresence>,
        document.body
    );
};

export default Overlay;
