import Row from '../row/row.component';
import Offer from '../offer/offer.component';

const OffersList = ({ offers, clickedOffer, onOfferClick = () => null }) => {
    const handleOnOfferClick = (e, offer) => {
        const offerRect = e.target.getBoundingClientRect();
        const top = offerRect.top;
        const left = e.target.offsetLeft - e.target.parentNode.offsetLeft;
        const width = offerRect.width;
        onOfferClick({ ...offer, top, left, width });
    };
    return (
        <Row flexWrap="wrap">
            {offers.map((offer) => (
                <Offer
                    key={'f_' + offer.id}
                    data={offer}
                    style={{
                        transform:
                            clickedOffer?.id === offer.id
                                ? 'scale(0)'
                                : 'scale(1)',
                    }}
                    onClick={(e) => handleOnOfferClick(e, offer)}
                />
            ))}
        </Row>
    );
};

export default OffersList;
