import { createContext } from 'react';
import useLocalStorage from 'use-local-storage';
import { GET_ADDRESS, GET_POSITION } from '../api/location.api';

export const LocationContext = createContext();

const initialLocation = {
    address: null,
    lat: null,
    lon: null,
};

const LocationContextProvider = ({ children }) => {
    const [location, setLocation] = useLocalStorage(
        'location',
        initialLocation
    );
    const getPosition = (query) => GET_POSITION({ query });
    const getAddress = (lon, lat) => GET_ADDRESS({ lon, lat });
    const clearLocation = () => setLocation(initialLocation);

    return (
        <LocationContext.Provider
            value={{
                location,
                setLocation,
                getPosition,
                getAddress,
                clearLocation,
            }}
        >
            {children}
        </LocationContext.Provider>
    );
};

export default LocationContextProvider;
