import { useTranslation } from 'react-i18next';
import Row from '../../../components/row/row.component';
import { Button } from './menu.elements';

const LanguageButtonsList = ({ locales }) => {
    const { i18n } = useTranslation();

    const handleOnClick = (locale) => i18n.changeLanguage(locale);

    return (
        <Row justifyContent="center">
            {locales.map((locale) => (
                <Button key={locale} onClick={() => handleOnClick(locale)}>
                    {locale.toUpperCase()}
                </Button>
            ))}
        </Row>
    );
};

export default LanguageButtonsList;
