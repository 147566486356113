import { useContext, useState } from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { OffersContext } from '../../../contexts/offers.context';
import scaleAnimation from '../../../animations/motion/scale.animation';
import fadeAnimation from '../../../animations/motion/fade.animation';
import OffersList from '../../../components/offers-list/offers-list.component';
import Offer from '../../../components/offer/offer.component';
import Overlay from '../../../components/overlay/overlay.component';
import Controls from '../../../components/controls/controls.component';

const OffersSection = () => {
    const { t } = useTranslation();
    const { offers = [], offerMethods } = useContext(OffersContext);
    const [clickedOffer, setClickedOffer] = useState(null);

    const offerControls = {
        controls: [
            {
                icon: '/icons/angry_smile.png',
                onClick: () =>
                    offerMethods
                        .dislike(clickedOffer.id)
                        .then(() => setClickedOffer(null)),
            },
            {
                text: t('CONTROLS_NAVIGATE'),
                onClick: () => offerMethods.navigate(clickedOffer),
            },
            {
                text: t('CONTROLS_ORDER'),
                onClick: () => offerMethods.order(clickedOffer),
            },
            {
                icon: '/icons/love_smile.png',
                onClick: () =>
                    offerMethods
                        .like(clickedOffer.id)
                        .then(() => setClickedOffer(null)),
            },
        ],
        options: {
            animateButtons: {
                ...scaleAnimation,
                animate: {
                    ...scaleAnimation.animate,
                    transition: { delay: 0.3 },
                },
            },
        },
    };
    return (
        <>
            <OffersList
                offers={offers}
                onOfferClick={setClickedOffer}
                clickedOffer={clickedOffer}
            />
            <Overlay
                blurElement={document.getElementById('home-slide')}
                showOverlay={clickedOffer?.id}
                hideOverlay={setClickedOffer}
                {...fadeAnimation}
                exit={{ ...fadeAnimation.exit, pointerEvents: 'none' }}
                style={{
                    maxWidth: '600px',
                    margin: '0 auto',
                    position: 'relative',
                }}
            >
                <Offer
                    as={motion.div}
                    data={clickedOffer}
                    {...scaleAnimation}
                    initial={{
                        ...scaleAnimation.initial,
                        opacity: 1,
                        marginTop: '20vh',
                    }}
                    transition={{
                        duration: 0.2,
                    }}
                />
                <Controls {...offerControls} />
            </Overlay>
        </>
    );
};

export default OffersSection;
