import styled from 'styled-components';

export const Button = styled('button')`
    width: max-content;
    background: ${({ backgroundColor }) =>
        backgroundColor || 'var(--bg-secondary)'};
    color: var(--text-secondary);
    padding: 1em;
    font-size: 1.3rem;
    border: 1px solid var(--text-primary);
    border-radius: 5px;
    cursor: pointer;
    margin: 3px 3px;
`;

export const Heading = styled('h1')`
    color: #fff;
    text-align: center;
    font-size: 1.6rem;
`;

export const LogoutButton = styled(Button)`
    color: var(--text-primary);
    background: var(--bg-primary);
    margin-top: 20px;
`;

export const ThemeButton = styled('button')`
    background: ${({ background }) => background || 'transparent'};
    border-color: ${({ borderColor }) => borderColor || '#fff'};
    border-width: 2px;
    border-style: solid;
    width: 40px;
    height: 40px;
    margin: 5px;
    opacity: 0.9;
    cursor: pointer;
`;
