import { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    DELETE_OFFER_FAVORITE,
    GET_OFFER,
    GET_OFFER_FAVORITE,
    POST_OFFER_FAVORITE,
    POST_OFFER_FEEDBACK,
} from '../api/offer.api';
import { AuthContext } from './auth.context';
import { LocationContext } from './location.context';

const models = ['simple', 'alpha', 'beta', 'gamma'];

export const OffersContext = createContext();
const OffersContextProvider = ({ children }) => {
    const { i18n } = useTranslation();
    const { token } = useContext(AuthContext);
    const {
        location: { address, lat, lon },
    } = useContext(LocationContext);
    const [offers, setOffers] = useState([]);
    const [favoriteOffers, setFavoriteOffers] = useState([]);
    const [model, setModel] = useState(models[0]);

    useEffect(() => {
        GET_OFFER_FAVORITE(token).then((data) => setFavoriteOffers(data));
    }, [token]);

    useEffect(() => {
        if (address && lat && lon) {
            GET_OFFER({ lat, lon, lang: i18n.language, model }, token).then(
                (data) => setOffers(data)
            );
        } else {
            setOffers([]);
        }
    }, [address, lat, lon, token, i18n.language, model]);

    const removeOffer = (offerItemId) =>
        setOffers((offers) =>
            offers.filter((offer) => offer.id !== offerItemId)
        );

    const addOfferToFavorite = (offerItemId) =>
        setFavoriteOffers((favoriteOffers) => [
            ...favoriteOffers,
            offers.find((offer) => offer.id === offerItemId),
        ]);

    const removeOfferFromFavorite = (offerItemId) =>
        setFavoriteOffers((offers) =>
            offers.filter((offer) => offer.id !== offerItemId)
        );

    const offerMethods = {
        like: (offerItemId) =>
            POST_OFFER_FAVORITE({ offerItemId }, token)
                //.then(() => POST_OFFER_FEEDBACK({ offerItemId, type: 'LIKE' }, token))
                .then(() => addOfferToFavorite(offerItemId))
                .then(() => removeOffer(offerItemId)),
        dislike: (offerItemId) =>
            POST_OFFER_FEEDBACK({ offerItemId, type: 'DISLIKE' }, token).then(
                () => removeOffer(offerItemId)
            ),
        navigate: ({ id: offerItemId, restaurant, address }) =>
            POST_OFFER_FEEDBACK({ offerItemId, type: 'NAVIGATE' }, token).then(
                () =>
                    window.open(
                        `https://maps.google.com?q=${restaurant} ${address}`,
                        '_blank'
                    )
            ),
        order: ({ id: offerItemId, web }) =>
            POST_OFFER_FEEDBACK({ offerItemId, type: 'VISIT_URL' }, token).then(
                () => (web ? window.open(web, '_blank') : null)
            ),
    };

    const favoriteOfferMethods = {
        dislike: (offerItemId) =>
            DELETE_OFFER_FAVORITE({ offerItemId }, token).then(() =>
                removeOfferFromFavorite(offerItemId)
            ),
    };

    return (
        <OffersContext.Provider
            value={{
                offers,
                favoriteOffers,
                offerMethods,
                favoriteOfferMethods,
                models,
                model,
                setModel,
            }}
        >
            {children}
        </OffersContext.Provider>
    );
};

export default OffersContextProvider;
