import fetchJsonp from 'fetch-jsonp';

// Get address from longitude & latitude
// Props: data: {lon: string, lat: string}
// Response: Address Object
export const GET_ADDRESS = ({ lon, lat }) =>
    fetch(
        `https://ares.objedname.eu/nominatim/reverse.php?lon=${lon}&lat=${lat}&format=json`
    )
        .then((res) => res.json())
        .catch((err) => console.log(err));

// Get address from search query
// Props: data: {query: string}
// Response: Address Object
export const GET_POSITION = ({ query }) =>
    fetchJsonp(
        `https://ares.objedname.eu/address_whisper?callback=cb&s=${query}`,
        {
            jsonpCallback: 'cb',
            jsonpCallbackFunction: 'cb',
        }
    )
        .then((response) => response.json())
        .catch((err) => console.log(err));
