const scale = {
    initial: {
        transform: 'scale(0)',
    },
    animate: {
        transform: 'scale(1)',
    },
    exit: {
        transform: 'scale(0)',
    },
};

export default scale;
