import { useContext } from 'react';
import { ThemeContext } from '../../../contexts/theme.context';
import { ThemeButton } from './menu.elements';
import Row from '../../../components/row/row.component';

const ThemeButtonsList = ({ themes = [] }) => {
    const { setTheme, initialTheme } = useContext(ThemeContext);

    const handleOnThemeButtonClick = (theme) => {
        console.log(theme);
        setTheme({
            ...initialTheme,
            ...theme,
        });
    };

    return (
        <Row justifyContent="center">
            {themes.map(({ coverColor, theme }) => (
                <ThemeButton
                    key={coverColor}
                    background={coverColor}
                    onClick={() => handleOnThemeButtonClick(theme)}
                />
            ))}
        </Row>
    );
};

export default ThemeButtonsList;
