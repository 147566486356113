import Slide from '../../components/slide/slide.elements';
import RegisterForm from '../../forms/register.form';

const Register = () => {
    return (
        <Slide>
            <RegisterForm />
        </Slide>
    );
};

export default Register;
