import { useContext, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { AnimatePresence } from 'framer-motion';
import Form from '../components/form/form.elements';
import isValidEmail from '../validation/is-valid-email.validation';
import isValidPhoneNumber from '../validation/is-valid-phone-number.validation';
import { POST_USER, POST_USER_LOGIN } from '../api/user.api';
import { AuthContext } from '../contexts/auth.context';
import AnimatedErrorMessage from './components/animated-error-message/animated-error-message.component';
import Grid from '../components/grid/grid.component';

const Register = () => {
    const { saveUser } = useContext(AuthContext);
    const { t } = useTranslation();
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm();

    const password = useRef({});
    password.current = watch('password', '');

    const handleOnSubmit = ({
        username,
        email,
        password,
        telephoneStart,
        telephoneNumber,
    }) => {
        const telephone =
            telephoneStart && telephoneNumber
                ? `${telephoneStart.trim()}${telephoneNumber.trim()}`
                : '';
        POST_USER({
            username: username.trim(),
            email: email.trim(),
            password,
            telephone,
        }).then(({ data }) => {
            if (data) {
                POST_USER_LOGIN({
                    email: data.email,
                    password: password,
                }).then(
                    ({
                        data: { token, sessionId, userId, username, email },
                    }) => {
                        saveUser({
                            token,
                            sessionId,
                            userId,
                            username,
                            email,
                        });
                    }
                );
            }
        });
    };

    return (
        <Form onSubmit={handleSubmit(handleOnSubmit)}>
            <Grid
                gridTemplateAreas={[
                    `'username'
            'email'
            'telephone'
            'password'
            'passwordConfirm'`,
                    `'username email'
            'telephone .'
            'password password'
            'passwordConfirm passwordConfirm'`,
                ]}
                gridColumnGap="1em"
                marginBottom="10px"
            >
                <Form.Field area="username">
                    <Form.Label>{t('FORM_USERNAME')}*</Form.Label>
                    <Form.InputField hasError={!!errors?.username}>
                        <Form.Input
                            type="text"
                            {...register('username', {
                                required: t('FORM_VALIDATION_REQUIRED'),
                                minLength: {
                                    value: 3,
                                    message: t(
                                        'FORM_VALIDATION_USERNAME_TOO_SHORT',
                                        {
                                            numOfChars: 3,
                                        }
                                    ),
                                },
                                maxLength: {
                                    value: 16,
                                    message: t(
                                        'FORM_VALIDATION_USERNAME_TOO_LONG',
                                        {
                                            numOfChars: 16,
                                        }
                                    ),
                                },
                            })}
                            placeholder={t('FORM_USERNAME_PLACEHOLDER')}
                        />
                    </Form.InputField>
                    <AnimatePresence>
                        {!!errors?.username && (
                            <AnimatedErrorMessage>
                                {errors?.username.message}
                            </AnimatedErrorMessage>
                        )}
                    </AnimatePresence>
                </Form.Field>
                <Form.Field area="email">
                    <Form.Label>{t('FORM_EMAIL')}*</Form.Label>
                    <Form.InputField hasError={!!errors?.email}>
                        <Form.Input
                            type="email"
                            {...register('email', {
                                required: t('FORM_VALIDATION_REQUIRED'),
                                validate: {
                                    isValidEmail: (value) =>
                                        isValidEmail(value) ||
                                        t('FORM_VALIDATION_EMAIL_NOT_VALID'),
                                },
                            })}
                            placeholder={t('FORM_EMAIL_PLACEHOLDER')}
                        />
                    </Form.InputField>
                    <AnimatePresence>
                        {!!errors?.email && (
                            <AnimatedErrorMessage>
                                {errors?.email.message}
                            </AnimatedErrorMessage>
                        )}
                    </AnimatePresence>
                </Form.Field>
                <Form.Field area="telephone">
                    <Form.Label>{t('FORM_TELEPHONE_LABEL')}</Form.Label>
                    <Form.InputField hasError={!!errors?.telephoneNumber}>
                        <Form.Select {...register('telephoneStart')}>
                            <Form.Option>+420</Form.Option>
                        </Form.Select>
                        <Form.Input
                            type="text"
                            {...register('telephoneNumber', {
                                required: t('FORM_VALIDATION_REQUIRED'),
                                validate: {
                                    isValidPhoneNumber: (value) =>
                                        !!value.length &&
                                        !isValidPhoneNumber(value)
                                            ? t(
                                                  'FORM_VALIDATION_TELEPHONE_NOT_VALID'
                                              )
                                            : true,
                                },
                            })}
                            placeholder={t('FORM_TELEPHONE_PLACEHOLDER')}
                        />
                    </Form.InputField>
                    <AnimatePresence>
                        {!!errors?.telephoneNumber && (
                            <AnimatedErrorMessage>
                                {errors?.telephoneNumber.message}
                            </AnimatedErrorMessage>
                        )}
                    </AnimatePresence>
                </Form.Field>
                <Form.Field area="password">
                    <Form.Label>{t('FORM_PASSWORD')}*</Form.Label>
                    <Form.InputField hasError={!!errors?.password}>
                        <Form.Input
                            type="password"
                            {...register('password', {
                                required: t('FORM_VALIDATION_REQUIRED'),
                                minLength: {
                                    value: 6,
                                    message: t(
                                        'FORM_VALIDATION_PASSWORD_TOO_SHORT',
                                        {
                                            numOfChars: 6,
                                        }
                                    ),
                                },
                            })}
                            placeholder={t('FORM_PASSWORD_PLACEHOLDER')}
                        />
                    </Form.InputField>
                    <AnimatePresence>
                        {!!errors?.password && (
                            <AnimatedErrorMessage>
                                {errors?.password.message}
                            </AnimatedErrorMessage>
                        )}
                    </AnimatePresence>
                </Form.Field>
                <Form.Field area="passwordConfirm">
                    <Form.Label>{t('FORM_PASSWORD_CONFIRM')}*</Form.Label>
                    <Form.InputField hasError={!!errors?.passwordConfirm}>
                        <Form.Input
                            type="password"
                            {...register('passwordConfirm', {
                                required: t('FORM_VALIDATION_REQUIRED'),
                                validate: {
                                    isEqualToPassword: (value) =>
                                        value === password.current ||
                                        t(
                                            'FORM_VALIDATION_PASSWORD_CONFIRM_NOT_EQUAL'
                                        ),
                                },
                            })}
                            placeholder={t('FORM_PASSWORD_CONFIRM_PLACEHOLDER')}
                        />
                    </Form.InputField>
                    <AnimatePresence>
                        {!!errors?.passwordConfirm && (
                            <AnimatedErrorMessage>
                                {errors?.passwordConfirm.message}
                            </AnimatedErrorMessage>
                        )}
                    </AnimatePresence>
                </Form.Field>
            </Grid>
            <Form.Submit float="right">{t('FORM_SUBMIT_REGISTER')}</Form.Submit>
        </Form>
    );
};

export default Register;
