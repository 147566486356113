// Get food offers (20)
// Props: data: {lat: string, lon: string, lang = ["cs", "en"]}, token: string
// Response: Food Offer data
export const GET_OFFER = ({ lat, lon, lang, model }, token) =>
    fetch(
        `https://api.hungerbot.cz/v1/offer?lat=${lat}&lon=${lon}&lang=${lang}&model=${model}`,
        {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
    )
        .then((res) => res.json())
        .then(({ data }) =>
            data.map((foodOffer) => ({
                id: foodOffer?._id,
                dateValid: foodOffer?.dateValid,
                heading: foodOffer?.name,
                lang: foodOffer?.lang,
                price: foodOffer?.price,
                currency: foodOffer?.currency,
                restaurant: foodOffer?.offerer.name,
                address: foodOffer?.offerer?.address,
                web: foodOffer?.offerer?.web,
                lon: foodOffer?.offerer?.lon,
                lat: foodOffer?.offerer?.lat,
            }))
        )
        .catch((err) => err);

// Get user's favorite food offers
// Props: token: string
// Response: Array of favorite food offers
export const GET_OFFER_FAVORITE = (token) =>
    fetch(`https://api.hungerbot.cz/v1/offer/favorite`, {
        method: 'GET',
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
        .then((res) => res.json())
        .then(({ data }) =>
            data.map(({ offerItemCopy: foodOffer }) => ({
                id: foodOffer?._id,
                dateValid: foodOffer?.dateValid,
                heading: foodOffer?.name,
                lang: foodOffer?.lang,
                price: foodOffer?.price,
                currency: foodOffer?.currency,
                restaurant: foodOffer?.offerer.name,
                address: foodOffer?.offerer?.address,
                web: foodOffer?.offerer?.web,
                lon: foodOffer?.offerer?.lon,
                lat: foodOffer?.offerer?.lat,
            }))
        )
        .catch((err) => err);

// Add food offer to user's favorite food offers
// Props: data: {offerItemId: string}
// Response: Food Offer Data
export const POST_OFFER_FAVORITE = ({ offerItemId }, token) =>
    fetch('https://api.hungerbot.cz/v1/offer/favorite', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ offerItemId }),
    })
        .then((res) => res.json())
        .catch((err) => err);

// Remove food offer from user's favorite food offers
// Props: data: {offerItemId: string}
// Response: Success / Error message
export const DELETE_OFFER_FAVORITE = ({ offerItemId }, token) =>
    fetch(
        `https://api.hungerbot.cz/v1/offer/favorite?offerItemId=${offerItemId}`,
        {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${token}`,
            },
        }
    )
        .then((res) => res.json())
        .catch((err) => err);
// Push user's feedback to server
// Props: data: {offerItemId: string, type: string ["LIKE"]}
// Response: Success / Error message
export const POST_OFFER_FEEDBACK = ({ offerItemId, type }, token) =>
    fetch('https://api.hungerbot.cz/v1/offer/feedback', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ offerItemId, type }),
    })
        .then((res) => res.json())
        .catch((err) => err);
