import styled from 'styled-components';

export const MenuButton = styled('button')`
    width: 3em;
    padding: 0.5em;
    height: 100%;
    background: transparent url('/icons/menu_list.svg') no-repeat center;
    background-size: 80%;
    border: none;
    cursor: pointer;
    float: right;
    margin-right: 2rem;
`;
export const Logo = styled('img').attrs({
    src: '/images/logo.png',
    draggable: false,
})`
    display: block;
    height: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`;
