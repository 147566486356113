import Row from '../../../components/row/row.component';
import { SlideNavigationLink } from './auth.elements';

const SlideNavigation = ({ links, onLinkClick, activeLink }) => (
    <Row justifyContent="center">
        {links.map((link) => (
            <SlideNavigationLink
                onClick={() => onLinkClick(link)}
                active={link.index === activeLink}
            >
                {link.label}
            </SlideNavigationLink>
        ))}
    </Row>
);

export default SlideNavigation;
