import styled, { css } from 'styled-components';

const Suggestion = styled('div')`
    width: 98%;
    padding: 5px;
    font-size: 1.4rem;
    background-color: var(--bg-secondary);
    color: var(--text-secondary);
    margin: 0 auto 3px auto;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
        background-color: var(--bg-secondary-darker);
    }
    ${({ selected }) =>
        selected &&
        css`
            background-color: var(--bg-secondary-darker);
        `}
`;

export default Suggestion;
