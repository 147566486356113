import { createContext, useContext, useEffect, useState } from 'react';
import { AuthContext } from './auth.context';
import {
    DELETE_KEYWORD_WEIGHT,
    GET_KEYWORD,
    GET_KEYWORD_WEIGHT,
    POST_KEYWORD,
    POST_KEYWORD_WEIGHT,
} from '../api/keyword.api';
import { useTranslation } from 'react-i18next';

export const IngredientsContext = createContext();

const IngredientsContextProvider = ({ children }) => {
    const { i18n } = useTranslation();
    const { token } = useContext(AuthContext);
    const [ingredients, setIngredients] = useState([]);
    const [likedIngredients, setLikedIngredients] = useState([]);
    const [dislikedIngredients, setDislikedIngredients] = useState([]);

    useEffect(() => {
        GET_KEYWORD({ lang: i18n.language }, token)
            .then((ing) => {
                setIngredients(ing);
                return ing;
            })
            .then(() => {
                GET_KEYWORD_WEIGHT(token).then(({ data }) => {
                    let likedIg = [];
                    let dislikedIg = [];
                    data.forEach((ingredient) => {
                        if (ingredient.weight > 0) {
                            likedIg.push(ingredient);
                        } else if (ingredient.weight < 0) {
                            dislikedIg.push(ingredient);
                        }
                    });
                    setLikedIngredients(likedIg);
                    setDislikedIngredients(dislikedIg);
                });
            });
    }, [token, i18n.language]);

    const ingredientMethods = {
        addNew: ({ name, lang }) =>
            POST_KEYWORD({ name, lang }, token).then(({ data }) => ({
                keywordId: data.id,
            })),
        like: ({ keywordId, name }) =>
            POST_KEYWORD_WEIGHT(
                { keywordId, weight: 1, name, lang: i18n.language },
                token
            ).then(() =>
                setLikedIngredients((likedIngredients) => [
                    ...likedIngredients,
                    { keyword: keywordId, name },
                ])
            ),
        dislike: ({ keywordId, name }) =>
            POST_KEYWORD_WEIGHT(
                { keywordId, weight: -1, name, lang: i18n.language },
                token
            ).then(() =>
                setDislikedIngredients((dislikedIngredients) => [
                    ...dislikedIngredients,
                    { keyword: keywordId, name, lang: i18n.language },
                ])
            ),
        remove: ({ keywordId, status }) =>
            DELETE_KEYWORD_WEIGHT({ keywordId }, token).then(() => {
                switch (status) {
                    case 'LIKED':
                        setLikedIngredients((likedIngredients) =>
                            likedIngredients.filter(
                                (likedIngredient) =>
                                    likedIngredient.keyword !== keywordId
                            )
                        );
                        break;
                    case 'DISLIKED':
                        setDislikedIngredients((dislikedIngredients) =>
                            dislikedIngredients.filter(
                                (dislikedIngredient) =>
                                    dislikedIngredient.keyword !== keywordId
                            )
                        );
                        break;
                    default:
                        return;
                }
            }),
    };

    return (
        <IngredientsContext.Provider
            value={{
                ingredients,
                likedIngredients,
                dislikedIngredients,
                ingredientMethods,
            }}
        >
            {children}
        </IngredientsContext.Provider>
    );
};

export default IngredientsContextProvider;
